const BASEURL = "https://casa-barro-public.s3.ap-northeast-2.amazonaws.com/"

// landing
const LANDING_FOLDER = "landing/"
const LANDING_IMG_1 = BASEURL + LANDING_FOLDER + "landing.jpg"
// const LANDING_VID_1 = BASEURL + LANDING_FOLDER + "landing.mp4"
const LANDING_VID_1 = BASEURL + LANDING_FOLDER + "landing_new.mp4"

// vision
const VISION_FOLDER = "vision/"
const VISION_COVER = BASEURL + VISION_FOLDER + "cover.jpg"
const VISION_IMG_1 = BASEURL + VISION_FOLDER + "image1.jpg"
const VISION_IMG_2 = BASEURL + VISION_FOLDER + "image2.jpg"
const VISION_IMG_3 = BASEURL + VISION_FOLDER + "image3.jpg"
const VISION_IMG_4 = BASEURL + VISION_FOLDER + "image4.jpg"
const VISION_IMG_5 = BASEURL + VISION_FOLDER + "image5.jpg"

// residence
const RESIDENCE_FOLDER = "residence/"
const RESIDENCE_COVER = BASEURL + RESIDENCE_FOLDER + "cover.jpg"
const RESIDENCE_IMG_1 = BASEURL + RESIDENCE_FOLDER + "image1.jpg"
const RESIDENCE_IMG_2 = BASEURL + RESIDENCE_FOLDER + "image2.jpg"
const RESIDENCE_IMG_3 = BASEURL + RESIDENCE_FOLDER + "image3.jpg"
const RESIDENCE_IMG_4 = BASEURL + RESIDENCE_FOLDER + "image4.jpg"
const RESIDENCE_IMG_5 = BASEURL + RESIDENCE_FOLDER + "image5.jpg"
const RESIDENCE_IMG_6 = BASEURL + RESIDENCE_FOLDER + "image6.jpg"
const RESIDENCE_FLOOR_0 = BASEURL + RESIDENCE_FOLDER + "floor0.jpg"
const RESIDENCE_FLOOR_1 = BASEURL + RESIDENCE_FOLDER + "floor1.jpg"
const RESIDENCE_BUILDING = BASEURL + RESIDENCE_FOLDER + "building.jpg"

// amenities
const AMENITIES_FOLDER = "amenities/"
const AMENITIES_COVER = BASEURL + AMENITIES_FOLDER + "cover.jpg"
const AMENITIES_IMG_1 = BASEURL + AMENITIES_FOLDER + "image1.jpg"
const AMENITIES_IMG_2 = BASEURL + AMENITIES_FOLDER + "image2.jpg"
const AMENITIES_IMG_3 = BASEURL + AMENITIES_FOLDER + "image3.jpg"
const AMENITIES_IMG_4 = BASEURL + AMENITIES_FOLDER + "image4.jpg"

// neighborhood
const NEIGHBORHOOD_FOLDER = "neighborhood/"
const NEIGHBORHOOD_COVER = BASEURL + NEIGHBORHOOD_FOLDER + "cover.jpg"
const NEIGHBORHOOD_IMG_1 = BASEURL + NEIGHBORHOOD_FOLDER + "image1.jpg"
const NEIGHBORHOOD_IMG_2 = BASEURL + NEIGHBORHOOD_FOLDER + "image2.jpg"
const NEIGHBORHOOD_IMG_3 = BASEURL + NEIGHBORHOOD_FOLDER + "image3.jpg"
const NEIGHBORHOOD_IMG_4 = BASEURL + NEIGHBORHOOD_FOLDER + "image4.jpg"
const NEIGHBORHOOD_IMG_5 = BASEURL + NEIGHBORHOOD_FOLDER + "image5.jpg"

// collaboration
const COLLABORATION_FOLDER = "collaboration/"
const COLLABORATION_COVER = BASEURL + COLLABORATION_FOLDER + "cover.jpg"

// contact

export {
    //landing
    LANDING_IMG_1, LANDING_VID_1,

    // vision
    VISION_COVER,
    VISION_IMG_1, VISION_IMG_2, VISION_IMG_3, VISION_IMG_4, VISION_IMG_5,

    // residence
    RESIDENCE_COVER, 
    RESIDENCE_IMG_1, RESIDENCE_IMG_2, RESIDENCE_IMG_3,
    RESIDENCE_IMG_4, RESIDENCE_IMG_5, RESIDENCE_IMG_6,
    RESIDENCE_FLOOR_0, RESIDENCE_FLOOR_1, RESIDENCE_BUILDING,

    // amenities
    AMENITIES_COVER,
    AMENITIES_IMG_1, AMENITIES_IMG_2, AMENITIES_IMG_3, AMENITIES_IMG_4,

    // neighborhood
    NEIGHBORHOOD_COVER,
    NEIGHBORHOOD_IMG_1, NEIGHBORHOOD_IMG_2, NEIGHBORHOOD_IMG_3,
    NEIGHBORHOOD_IMG_4, NEIGHBORHOOD_IMG_5,

    // collaboration
    COLLABORATION_COVER,

    // contact
}