import React from "react";

const Logo = (props) => {
    let { color } = props;
    if (color == undefined || color == "") {
        color = "#833316";
    }

    return (
        <svg
            width="158"
            height="30"
            viewBox="0 0 158 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M83.8171 28.8816C83.5816 28.8816 83.4049 29.0288 83.2283 29.4999C83.1694 29.6471 83.0516 29.706 82.8161 29.706H81.8151C81.4913 29.706 81.2263 29.441 81.2263 29.1172V9.39203C81.2263 9.06819 81.4913 8.80322 81.8151 8.80322H83.2871C83.611 8.80322 83.876 9.06819 83.876 9.39203V14.6913C83.876 15.3685 84.0232 15.6334 84.4059 15.6334C84.9358 15.6334 85.5541 14.6913 87.4971 14.6913C89.4402 14.6913 91.1478 15.604 92.0016 17.1938C92.6492 18.4008 92.9436 19.9906 92.9436 22.3459C92.9436 24.7011 92.7081 26.2026 92.031 27.4685C91.1772 29.0583 89.4108 30.0004 87.2616 30.0004C84.8769 30.0004 84.4353 28.8816 83.8171 28.8816ZM89.7641 25.9965C90.2646 25.0838 90.294 24.0534 90.294 22.3459C90.294 20.6383 90.2351 19.6079 89.7641 18.6952C89.293 17.7826 88.2332 17.341 86.9967 17.341C85.7602 17.341 84.7297 17.7826 84.2292 18.6952C83.7288 19.6079 83.6993 20.6383 83.6993 22.3459C83.6993 24.0534 83.7288 25.0838 84.2292 25.9965C84.7297 26.9091 85.7602 27.3507 86.9967 27.3507C88.2332 27.3507 89.2636 26.9091 89.7641 25.9965Z"
                fill={color}
            />
            <path
                d="M96.8003 25.3192C96.8003 22.3751 98.7434 20.8148 102.1 20.8148H105.25C105.426 20.8148 105.544 20.697 105.544 20.5204V19.3133C105.544 17.8119 104.573 17.017 102.806 17.017C101.04 17.017 100.098 17.7235 100.098 18.9895C100.098 19.3428 99.8915 19.49 99.4205 19.49H98.0662C97.6835 19.49 97.4774 19.2839 97.4774 18.9012C97.4774 16.2515 99.4499 14.6912 102.836 14.6912C106.221 14.6912 108.194 16.3104 108.194 19.4016V29.117C108.194 29.4409 107.929 29.7058 107.605 29.7058H106.604C106.427 29.7058 106.28 29.6469 106.192 29.4997C106.045 29.2348 105.897 28.8815 105.603 28.8815C105.073 28.8815 104.337 30.0002 102.158 30.0002C98.92 30.0002 96.8003 28.1455 96.8003 25.3192ZM105.78 24.4654V23.5233C105.78 23.3467 105.662 23.2289 105.485 23.2289H102.394C100.451 23.2289 99.5383 23.8766 99.5383 25.2603C99.5383 26.7618 100.51 27.5567 102.365 27.5567C104.69 27.5567 105.78 26.5557 105.78 24.4654Z"
                fill={color}
            />
            <path
                d="M116.997 21.0503V29.117C116.997 29.4408 116.732 29.7058 116.408 29.7058H114.936C114.612 29.7058 114.347 29.4408 114.347 29.117V15.5744C114.347 15.2505 114.612 14.9856 114.936 14.9856H116.084C116.261 14.9856 116.437 15.0444 116.496 15.1917C116.673 15.6038 116.79 16.1632 117.262 16.1632C117.939 16.1632 118.351 14.6912 121.118 14.6912C123.68 14.6912 125.799 16.8403 125.799 19.696C125.799 20.0199 125.534 20.2849 125.21 20.2849H123.709C123.385 20.2849 123.12 20.0199 123.12 19.696C123.12 18.2829 122.208 17.3408 120.382 17.3408C118.557 17.3408 116.997 18.4595 116.997 21.0503Z"
                fill={color}
            />
            <path
                d="M133.185 21.0503V29.117C133.185 29.4408 132.92 29.7058 132.596 29.7058H131.124C130.8 29.7058 130.535 29.4408 130.535 29.117V15.5744C130.535 15.2505 130.8 14.9856 131.124 14.9856H132.272C132.449 14.9856 132.626 15.0444 132.685 15.1917C132.861 15.6038 132.979 16.1632 133.45 16.1632C134.127 16.1632 134.539 14.6912 137.307 14.6912C139.868 14.6912 141.988 16.8403 141.988 19.696C141.988 20.0199 141.723 20.2849 141.399 20.2849H139.897C139.574 20.2849 139.309 20.0199 139.309 19.696C139.309 18.2829 138.396 17.3408 136.571 17.3408C134.745 17.3408 133.185 18.4595 133.185 21.0503Z"
                fill={color}
            />
            <path
                d="M146.433 27.5567C145.432 26.0552 145.344 24.4065 145.344 22.3457C145.344 20.2849 145.432 18.6362 146.433 17.1347C147.434 15.6333 149.142 14.6912 151.438 14.6912C153.735 14.6912 155.442 15.6333 156.443 17.1347C157.444 18.6362 157.532 20.2849 157.532 22.3457C157.532 24.4065 157.444 26.0552 156.443 27.5567C155.442 29.0581 153.735 30.0002 151.438 30.0002C149.142 30.0002 147.434 29.0581 146.433 27.5567ZM154.147 25.9669C154.677 25.172 154.794 24.0532 154.794 22.3457C154.794 20.6381 154.677 19.5194 154.147 18.7245C153.676 18.0179 152.763 17.3408 151.438 17.3408C150.113 17.3408 149.201 18.0179 148.73 18.7245C148.2 19.5194 148.082 20.6381 148.082 22.3457C148.082 24.0532 148.2 25.172 148.73 25.9669C149.201 26.6734 150.113 27.3506 151.438 27.3506C152.763 27.3506 153.676 26.6734 154.147 25.9669Z"
                fill={color}
            />
            <path
                d="M1.85475 13.7487C0.235527 12.2767 0 10.0981 0 7.65453C0 5.21097 0.323847 3.15013 1.79587 1.61923C2.82629 0.559367 4.47496 0 6.15306 0C7.68397 0 9.06768 0.441604 10.0098 1.32482C11.0108 2.23747 11.629 3.5623 11.629 5.00488C11.629 5.35817 11.4229 5.59369 11.1285 5.59369H9.56816C9.24432 5.59369 9.0088 5.38761 9.0088 5.12265C9.0088 4.38663 8.68494 3.7095 8.12557 3.26789C7.62508 2.85572 6.91852 2.64964 6.15306 2.64964C5.06377 2.64964 4.03334 3.03237 3.50342 3.73894C2.88516 4.56328 2.73797 5.5937 2.73797 7.65453C2.73797 9.36208 2.82629 10.6575 3.47398 11.4818C4.06279 12.2472 5.03432 12.6594 6.1825 12.6594C6.97739 12.6594 7.71341 12.4533 8.24334 12.0117C8.77327 11.5701 9.03823 10.9224 9.03823 10.2159C9.03823 9.892 9.24431 9.71536 9.5976 9.71536H11.2757C11.5701 9.71536 11.7173 9.92145 11.7173 10.3042C11.7173 11.8351 11.0696 13.2188 9.92144 14.1314C8.97935 14.8969 7.62508 15.3091 6.1825 15.3091C4.50439 15.3091 2.97349 14.7791 1.85475 13.7487Z"
                fill={color}
            />
            <path
                d="M15.8391 10.628C15.8391 7.68397 17.7822 6.12362 21.1384 6.12362H24.2885C24.4652 6.12362 24.5829 6.00586 24.5829 5.82922V4.62216C24.5829 3.12069 23.6114 2.3258 21.845 2.3258C20.0785 2.3258 19.1364 3.03237 19.1364 4.29831C19.1364 4.6516 18.9304 4.7988 18.4593 4.7988H17.1051C16.7223 4.7988 16.5162 4.59272 16.5162 4.20999C16.5162 1.56035 18.4888 0 21.8744 0C25.2601 0 27.2326 1.61923 27.2326 4.71048V14.4258C27.2326 14.7497 26.9676 15.0147 26.6438 15.0147H25.6428C25.4661 15.0147 25.3189 14.9558 25.2306 14.8086C25.0834 14.5436 24.9362 14.1903 24.6418 14.1903C24.1119 14.1903 23.3759 15.3091 21.1973 15.3091C17.9588 15.3091 15.8391 13.4543 15.8391 10.628ZM24.8185 9.77424V8.83215C24.8185 8.65551 24.7007 8.53774 24.5241 8.53774H21.4328C19.4897 8.53774 18.5771 9.18544 18.5771 10.5691C18.5771 12.0706 19.5486 12.8655 21.4034 12.8655C23.7292 12.8655 24.8185 11.8645 24.8185 9.77424Z"
                fill={color}
            />
            <path
                d="M32.2668 10.5397C32.2668 9.86257 32.4141 9.62704 32.8557 9.62704H34.3277C34.6515 9.62704 34.8871 9.83312 34.9165 10.2159C35.0931 11.8056 36.0058 12.7772 38.2433 12.7772C40.1275 12.7772 41.2462 12.1295 41.2462 10.8341C41.2462 9.53872 40.4513 9.06767 37.8605 8.62607C34.416 8.03726 32.8557 6.65355 32.8557 4.20999C32.8557 1.76643 35.0637 0 38.155 0C41.5112 0 43.572 1.58979 43.572 4.15111C43.572 4.76936 43.3954 5.00488 42.9832 5.00488H41.5112C41.1873 5.00488 40.9518 4.76936 40.9223 4.41607C40.8046 3.12069 39.9508 2.50244 38.2433 2.50244C36.5357 2.50244 35.5936 3.09125 35.5936 4.12167C35.5936 5.21097 36.4768 5.74089 39.0971 6.27082C42.571 6.94796 43.8958 8.2139 43.8958 10.8341C43.8958 13.4543 41.7761 15.3091 38.4199 15.3091C34.7398 15.3091 32.2668 13.5721 32.2668 10.5397Z"
                fill={color}
            />
            <path
                d="M48.22 10.628C48.22 7.68397 50.1631 6.12362 53.5193 6.12362H56.6694C56.846 6.12362 56.9638 6.00586 56.9638 5.82922V4.62216C56.9638 3.12069 55.9923 2.3258 54.2258 2.3258C52.4594 2.3258 51.5173 3.03237 51.5173 4.29831C51.5173 4.6516 51.3112 4.7988 50.8402 4.7988H49.4859C49.1032 4.7988 48.8971 4.59272 48.8971 4.20999C48.8971 1.56035 50.8696 0 54.2553 0C57.6409 0 59.6135 1.61923 59.6135 4.71048V14.4258C59.6135 14.7497 59.3485 15.0147 59.0246 15.0147H58.0237C57.847 15.0147 57.6998 14.9558 57.6115 14.8086C57.4643 14.5436 57.3171 14.1903 57.0227 14.1903C56.4927 14.1903 55.7567 15.3091 53.5781 15.3091C50.3397 15.3091 48.22 13.4543 48.22 10.628ZM57.1993 9.77424V8.83215C57.1993 8.65551 57.0816 8.53774 56.9049 8.53774H53.8137C51.8706 8.53774 50.9579 9.18544 50.9579 10.5691C50.9579 12.0706 51.9295 12.8655 53.7842 12.8655C56.11 12.8655 57.1993 11.8645 57.1993 9.77424Z"
                fill={color}
            />
            <path
                d="M79.3753 29.7202H77.8591C77.6935 29.7202 77.5427 29.6319 77.4617 29.4883L60.9714 0.839049C60.7947 0.533604 61.0155 0.150879 61.3688 0.150879H62.885C63.0506 0.150879 63.2015 0.239197 63.2824 0.382719L79.7728 29.032C79.9494 29.3375 79.7286 29.7202 79.3753 29.7202Z"
                fill={color}
            />
        </svg>
    );
};

export default Logo;
